<template>
  <v-data-table
    :items="inbox"
    :headers="headers"
    :hide-default-footer="true"
  />
</template>

<script>
import { mapState } from "vuex";

export default {

  computed: {
    ...mapState({
      inbox: (state) => state.conversations.inbox,
    }),
    headers() {
      return [
        { text: "Domain", value: "domain", width: "150px" },
        { text: "Message", value: "text" },
      ];
    },
  },
};
</script>

<style></style>
