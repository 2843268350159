<template>
  <v-card>
    <v-row
        no-gutters
        justify="space-around"
        align="center"
    >

      <v-col cols="12" md="2">

        <v-select
            v-model="conversationStage"
            :items="conversationStages"
            label="Stage"
        />
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-end">

          <v-btn large icon @click="dateAddDays(-1)">
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
          <v-menu
              v-model="calendar"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="50"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                  :value="getDate().date.toISOString().substring(0, 10)"
                  @change = "updateDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker :value="getDate().date.toISOString()" @change="updateDate" no-title scrollable/>
          </v-menu>
          <v-btn large icon class="mr-5" @click="dateAddDays(1)">
            <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
          </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            v-model="searchTerm"
            label="Keyword"
            class="mx-4"
            clearable
        />
      </v-col>

      <v-col cols="12" md="1">
        <v-btn block @click="search">
          Search
        </v-btn>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn block @click="clearFilter">
          Clear
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SearchFilter",
  data() {
    return {
      menu: false,
      calendar: false,
      awaitingSearch: false,
      searchTerm: '',
      dates: [],
      conversationStage: '',

    }
  },
  methods: {
    dateAddDays(days) {
      const datePerView = this.getDate(this.view())
      const newDropDate = new Date(datePerView.date);
      newDropDate.setDate(newDropDate.getDate() + days);
      this.updateDate(newDropDate);
    },

    updateDate(value) { //Expecting a Date
      const date = {
        date: new Date(value),
        view: this.view()
      }
      console.log("Received date to update", value)
      const updateIndex = this.dates.findIndex(item => item.view === date.view)
      this.dates = [
        ...this.dates.slice(0, updateIndex),
        date,
        ...this.dates.slice(updateIndex + 1)
      ]

      console.log("Updated dates", this.dates)

      this.search()

    },
    getDate() {
      const view = this.view()
      let date = this.dates.find(
          (d) => d.view && d.view === view
      );
      if(date==null) {
        date = {
          date: new Date(),
          view: view

        }
        this.dates = this.dates.concat(date);
      }
      return date;
    },

    search() {
      const searchFilter = {
        date: this.getDate(this.view()).date,
        searchTerm: this.searchTerm,
        conversationStage: this.conversationStage
      }
      this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
    },

    clearFilter() {
      this.date = '';
      this.searchTerm = '';
      this.conversationStage = ''
    },

    view() {
      return this.$route.name;
    }
  },
  computed: {
    ...mapState("conversations", ["conversationStages"]),

  },
  watch:{
    "$route.name"(value){
      this.date = this.getDate(value);
    }
  }

}
</script>

<style scoped>

</style>
