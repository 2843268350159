<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      left
      offset-x
  >
    <template #activator="{ on, attrs }">
      <!-- <v-btn v-bind="attrs" v-on="on" color="primary" icon> -->
      <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
          icon
          large
      >
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
    </template>
    <SearchFilter/>
  </v-menu>
</template>

<script>
import SearchFilter from "@/components/search/SearchFilter";

export default {
  components: {SearchFilter},
  data() {
    return {
      menu: false,
    }
  },
  name: "SearchMenu",


}
</script>

<style scoped>

</style>
